
.glassy-message {
    color: white;
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    text-align: center;
    font-size: 18px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* caorousel styling */
.multi-item-carousel .carousel-item {
    display: flex;
    justify-content: space-around; /* Space the items evenly */
    flex-wrap: nowrap; /* Prevent wrapping of items */
}

.multi-item-carousel .carousel-item .d-flex {
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal overflow */
}

.carousel-item-content {
    flex: 1 0 30%; /* Ensure 3 items fit in the container (30% each + spacing) */
    max-width: 30%;
    margin: 0 10px; /* Space between items */
    text-align: center;
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease;
}

.carousel-item-content:hover {
    transform: translateY(-5px); /* Add a hover effect */
}

@media (max-width: 992px) {
    .carousel-item-content {
        flex: 1 0 45%; /* On medium screens, show 2 items per row */
        max-width: 45%;
    }
}

@media (max-width: 768px) {
    .carousel-item-content {
        flex: 1 0 100%; /* On small screens, show 1 item per row */
        max-width: 100%;
    }
}


/* TradingData.css */

.hero {
    position: relative;
    color: #fff;
    text-align: center;
    padding: 4rem 1rem;
    background: rgba(0, 0, 0, 0.5); /* Optional overlay */
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.hero h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}

.hero .btn {
    margin-top: 1rem;
}



.card {
    border: none;
}

.card .text-success {
    color: #28a745;
}

.card .text-danger {
    color: #dc3545;
}

.table th,
.table td {
    text-align: center;
}

.table td {
    vertical-align: middle;
}



/* custom buttom styling */
.custom-navbar {
    background-color: rgba(16, 33, 73, 0.6) !important; 
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.custom-btn {
    border-radius: 30px;
    padding: 15px 30px;
    font-size: 18px;
    background-color: #102149 !important; 
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-btn i {
    margin-right: 10px;
    font-size: 22px;
}

.custom-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.custom-btn-primary {
    background-color: #007bff;
    color: white;
}

.custom-btn-secondary {
    background-color: #6c757d;
    color: white;
}


/* navbar styling */
.navbar-toggler{
    background-color: white !important;
}
.custom-navbar {
    font-size: 16px;
    padding: 15px 0;
}

.custom-navbar .navbar-brand {
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 20px;
}

.custom-navbar .navbar-brand i {
    margin-right: 8px;
    font-size: 22px;
}

.custom-navbar .nav-link {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
    color: #fff;
}

.custom-navbar .nav-link i {
    margin-right: 8px;
    font-size: 18px;
}

.custom-navbar .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

.dropdown-toggle{
    text-decoration: none !important;
    padding: 10px !important;
}
.dropdown-menu{
    background-color: #102149 !important;
}
/* table styles */

.styled-table {
    border-collapse: separate;
    border-spacing: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
}

.styled-table thead th {
    background-color: rgba(16, 33, 73, 0.6) !important;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 12px;
    border: none;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f2f2f2;
}

.styled-table tbody td {
    padding: 12px;
    text-align: center;
    border: none;
}

.table-row:hover {
    background-color: #e9ecef;
    cursor: pointer;
}

.text-success {
    color: #28a745 !important; /* Ensure strong green color for positive change */
}

.text-danger {
    color: #dc3545 !important; /* Strong red color for negative change */
}
