.chat-container {
    max-width: 600px;
    margin: 0 auto;
    height: 150vh;
    display: flex;
    flex-direction: column;
    background-color: #193066;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    background-color: #253a6c !important;
    border-radius: 10px;
    padding: 15px;
    color: #fff;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3);
}

.faq-section {
    background: rgba(50, 50, 50, 0.8);
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    color: #ddd;
}

.message-sent {
    background: linear-gradient(135deg, #4a90e2, #357ab7);
    color: #fff;
    padding: 10px;
    border-radius: 15px 15px 0 15px;
    margin: 5px 0;
    max-width: 70%;
    align-self: flex-end;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.message-received {
    background: rgba(255, 255, 255, 0.1);
    color: #eee;
    padding: 10px;
    border-radius: 15px 15px 15px 0;
    margin: 5px 0;
    max-width: 70%;
    align-self: flex-start;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.message-text {
    margin: 0;
}

img {
    max-width: 200px;
    border-radius: 10px;
    margin-top: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.chat-input {
    margin-top: auto;
    background-color: #253a6c !important;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
}

.chat-input textarea {
    color: #fff;
    border: 1px solid #555;
    border-radius: 10px;
}

.chat-input input[type="file"] {
    color: #fff;
    border: 1px solid #555;
    border-radius: 10px;
}

.chat-input button {
    background: linear-gradient(135deg, #4a90e2, #357ab7);
    color: #fff;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: background 0.3s ease;
}

.chat-input button:hover {
    background: linear-gradient(135deg, #357ab7, #4a90e2);
}

.addresses-section {
    background-color: #253a6c;
    color: #fff;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.addresses-section h5 {
    color: #4a90e2;
}

.addresses-section ul {
    list-style: none;
    padding: 0;
}

.addresses-section li {
    padding: 5px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.addresses-section li:last-child {
    border-bottom: none;
}
