.admin-sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #102149;
    color: white;
    padding: 20px;
    transition: left 0.3s ease-in-out;
    box-shadow: 4px 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1050;
}

.admin-sidebar.open {
    left: 0;
}

.admin-sidebar h3 {
    margin-bottom: 20px;
}

.admin-sidebar ul {
    list-style: none;
    padding: 0;
}

.admin-sidebar ul li {
    margin: 10px 0;
}

.admin-sidebar ul li a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 10px;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.admin-sidebar ul li a:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.admin-btn {
    align-items: center;
    display: flex !important;
    font-size: 16px !important;
    margin-left: 10px;
    text-decoration: none !important;
    border: none;
    background: none;
    cursor: pointer;
}

.admin-btn i {
    margin-right: 8px;
}