/* General container styling */
.profile-container {
    background-color: #f8f9fa; /* Light background */
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Card styling */
.profile-card {
    border: none;
    border-radius: 20px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.profile-card h1,
.profile-card h2 {
    color: #333;
    font-weight: bold;
}

.profile-card h2 {
    margin-bottom: 15px;
}

/* Button styling */
.profile-card .btn-primary,
.profile-card .btn-secondary {
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 16px;
    transition: all 0.3s ease;
}

.profile-card .btn-primary:hover {
    background-color: #007bff;
    transform: scale(1.05);
}

.profile-card .btn-secondary:hover {
    background-color: #6c757d;
    transform: scale(1.05);
}

/* Trading history list */
.trading-history-item {
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 15px;
    font-size: 14px;
    transition: background 0.3s ease;
}

.trading-history-item.bg-success {
    background-color: #d4edda !important;
    color: #155724 !important;
}

.trading-history-item.bg-danger {
    background-color: #f8d7da !important;
    color: #721c24 !important;
}

/* Modal styling */
.modal-content {
    border-radius: 15px;
    padding: 20px;
}

.modal-header {
    border-bottom: none;
}

.modal-title {
    font-size: 20px;
    font-weight: bold;
}

.modal-footer .btn {
    border-radius: 20px;
    padding: 10px 20px;
}

/* Responsive tweaks */
@media (max-width: 768px) {
    .profile-card h2 {
        font-size: 18px;
    }

    .trading-history-item {
        font-size: 12px;
    }
}
