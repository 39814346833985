/* QuickRecharge.css */

/* Custom styles for QuickRecharge without overriding existing ones */

/* Ensuring the card is in line with the main theme (glass effect) */
.quick-recharge-card {
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Glass effect */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    border: none;
}

.quick-recharge-card-body {
    padding: 20px;
    background: rgba(255, 255, 255, 0.3); /* Slightly transparent for glass effect */
    border-radius: 8px;
}

.quick-recharge-h2 {
    color: white;
    font-size: 1.8rem;
}

.quick-recharge-label {
    color: white;
}

/* Input and button styling to match the glassy theme */
.quick-recharge-input, .quick-recharge-button {
    border-radius: 10px;
}

/* Form elements styling */
.quick-recharge-input {
    background: rgba(255, 255, 255, 0.5);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px;
    backdrop-filter: blur(10px);
    transition: background-color 0.3s ease;
}

.quick-recharge-input:focus {
    background: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.8);
}

.quick-recharge-button {
    background-color: #102149; /* Matching button color from custom-btn */
    color: white;
    padding: 12px 25px;
    border: none;
    backdrop-filter: blur(10px);
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 100%;
}

.quick-recharge-button:hover {
    background-color: #007bff;
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

/* Glassy message styling */
.quick-recharge-glassy-message {
    color: white;
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); /* Safari support */
    text-align: center;
    font-size: 18px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Adjust the container padding for responsiveness */
@media (max-width: 992px) {
    .quick-recharge-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 768px) {
    .quick-recharge-container {
        padding-left: 10px;
        padding-right: 10px;
    }
}

